.ag-theme-alpine {
    --ag-foreground-color: #F0F0F0;
    --ag-header-background-color: #DEDEDE;
    // --ag-cell-horizontal-padding: 8px;
    // --ag-background-color: rgb(249, 245, 227);
    // --ag-header-foreground-color: rgb(204, 245, 172);
    // --ag-header-background-color: rgb(209, 64, 129);
    --ag-odd-row-background-color: #F6F6F6;
    // --ag-header-column-resize-handle-color: rgb(126, 46, 132);
    --ag-row-hover-color: #fecaca;
    --ag-font-size: 16px;
    --ag-font-family: 'Open Sans', sans-serif !important;
    --ag-header-height: 48px;
}

.ag-header-cell-wrap-text .ag-header-cell-comp-wrapper {
    white-space: normal;
    color: #1E1E1E;
    text-align: left;
}

.ag-theme-alpine .ag-header-group-cell {
    border-bottom: none !important;
}

.ag-theme-alpine .ag-header-cell {
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
    color: #1E1E1E !important;
    // align-items: flex-end;
    // border-bottom: 5px solid red;
}

.ag-theme-alpine .ag-header-viewport {
    border-bottom: none !important;
}

/* AgGrid Table Common Style */
.agGridTable {
    .ag-center-cols-viewport {
        background-color: #e8e8e8;
    }

    .ag-root-wrapper {
        //  border: none;
        border-radius: 4px !important;
    }

    .agGridHeadStyle {
        padding: 0 10px;
        color: #4C4A4C;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        font-family: var(--font-barlow) !important;
        background-color: #c5c4c5;
        border-right: 2px solid #d4d3d4;
    }

    .ag-row-odd {
        background-color: #c8c7c8;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    }

    .ag-row-even {
        background-color: #f2f2f2;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
    }

    .ag-row {
        .ag-cell {
            color: #4C4A4C;
            font-family: var(--font-barlow) !important;
            font-size: 14px;
            font-weight: 400;
            padding: 0 10px;
            height: 40px;
        }
    }

    .ag-center-cols-viewport {
        overflow: hidden;
    }
}

.ag-row-hover:not(.ag-full-width-row)::before,
.ag-row-hover.ag-full-width-row.ag-row-group::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.1);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.ag-row-focus {
    background-color: rgba(0, 0, 0, 0.1);
}

.ag-theme-alpine .ag-row {
    font-size: 14px;
    font-weight: 500;
    color: #4D4D4D;

}

::-webkit-scrollbar {
    width: 8px;
    background: #999;
}

::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 3px;
    border: 1px solid #333;
}

/* default ag grid settings */
.ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
.ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
.ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:not(.ag-column-hover).ag-column-resizing,
.ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
.ag-header-group-cell:first-of-type.ag-column-resizing,
.ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover,
.ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.ag-header-cell-label {
    justify-content: center;
}

.ag-header-cell:first-child .ag-header-cell-label {
    justify-content: flex-start;
}




.competitor-ag-grid {
    .ag-header {
        height: 45px !important;
        min-height: 45px !important;
        background-color: #707070;
        font-family: 'Barlow Condensed', sans-serif !important;
        font-size: 13px !important;
        font-weight: 600 !important
    }

    .ag-header-row {
        height: 45px !important;
        color: #fff;
    }

    .ag-row {
        height: 35px !important;
        line-height: 35px !important;
        border: none;
        background-color: transparent !important;
        font-family: 'Barlow Condensed', sans-serif !important;
        // font-size: 14px !important;
        // font-weight: 400 !important;
    }

    .ag-row-odd {
        background-color: #b7b7b7 !important;
    }

    .ag-cell {
        line-height: 35px !important;
        padding-left: 21px !important;
    }

    .ag-ltr .ag-cell {
        text-align: center;
    }

    .firstCol {
        font-weight: 600;
    }

    .ag-root-wrapper {
        // border: none;
        border-radius: 6px !important;
    }

    // .ag-center-cols-viewport {
    //     height: calc(100% + 16px) !important;
    // }
}


.ag-header,
.ag-root.ag-layout-normal,
.ag-root.ag-layout-auto-height,
.ag-root-wrapper {
    overflow: auto !important;
}

.ag-root-wrapper {
    border: var(--ag-borders) #CCCCCC;
    border-radius: 6px !important;
}

.ag-theme-alpine.modal-use .ag-root-wrapper {
    border: none !important;
    border-radius: 2px !important;
}

.ag-header {
    border-bottom: none;
}

.ag-row {
    border: none;
}

.edit-icon,
.grid-action {
    display: none;
}

.ag-theme-alpine .ag-row:hover {

    .edit-icon,
    .grid-action {
        display: block;
    }

}