@import '@fontsource/barlow-condensed/100.css';
@import '@fontsource/barlow-condensed/200.css';
@import '@fontsource/barlow-condensed/300.css';
@import '@fontsource/barlow-condensed/400.css';
@import '@fontsource/barlow-condensed/500.css';
@import '@fontsource/barlow-condensed/600.css';
@import '@fontsource/barlow-condensed/700.css';
@import '@fontsource/barlow-condensed/800.css';
@import '@fontsource/barlow-condensed/900.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "assets/css/aggrid";
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

:where(html) {
	--brand-red: #d5000a;
	--text-dark: #3d3d3d;
	--background-red: #fffafa;
}

.color-sh {
	background-color: #d5000a !important;
	color: #fff !important;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	font-family: 'DM Sans', 'Barlow Condensed', sans-serif;
	background-color: #E9ECEE;
}



ul,
li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}


:focus,
:focus-visible {
	box-shadow: none !important;
	//border: none !important;
	outline: none !important;
}


ul,
li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

::-webkit-file-upload-button {
	display: none;
}

:focus,
:focus-visible {
	box-shadow: none !important;
	//border-color: #ced4da !important;
	outline: none !important;
}

:focus-within {
	box-shadow: none !important;
	//border: none !important;
	border-color: #DEDEDE !important;
	outline: none !important;
}

.form-control:focus {
	border-color: #7C7C7C !important;

}



.ng-dropdown-panel {
	font-size: 14px;
	font-weight: 500;
	color: #7C7C7C;
	border: 1px solid #7C7C7C;
}

.ng-dropdown-panel.ng-select-bottom {
	border-top-color: #7C7C7C;
}

.modal-dialog {
	margin-top: 50px !important;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}

.progress-wrapper {
	text-align: left;
}

.progress-wrapper>div {
	background-color: #e5e7eb;
	width: 100%;
	height: 0.25rem;
	border-radius: 9999px;
	margin-top: -10px;
}

.progress-wrapper>div>div {
	background-color: rgb(72, 72, 224);
	border-radius: 9999px;
	height: 0.25rem;
}

.sub-head {
	align-items: center;
	width: 100%;
	// padding: 5px 0px;
}

.sub-head:focus,
.sub-head:focus-visible,
.sub-head:focus,
.sub-head:focus-within {
	// box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.sub-headtitle {
	// color: white;
	// padding: 0px 16px;
	/* text-align: center; */
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	font-family: 'DM Sans';
}

.sub-content {
	display: flex;
	justify-content: space-between;
}

// .ag-theme-alpine .ag-row-hover {

//     background-color: #d5000a26;
// }

.modal-2xl {
	--bs-modal-width: calc(98vw - 2px);
}

.modal-fullscreen {
	width: 90vw;
	max-width: none;
	height: auto !important;
	margin: 0;
}

.lease-uparrow {
	width: 15px;
	height: 10px;
}

.carousel-control-prev,
.carousel-control-next {
	width: 5%;
}

@media (max-width: 1400px) {

	.carousel-control-prev,
	.carousel-control-next {
		width: 8%;
	}
}


.positive {
	color: #0DAC1C;
}

.negative {
	color: #FD4F4F;
}

::-webkit-scrollbar {
	height: 8px;
	/* height of horizontal scrollbar ← You're missing this */
	width: 8px;
	/* width of vertical scrollbar */
	border: 1px solid #d5d5d5;
}



/* ===================== 
Custom Checkbox Design 
=======================*/
.custom-checkbox {
	display: flex;
	position: relative;
	padding-left: 25px;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	user-select: none;
	align-items: center;
	line-height: 1.5;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #000;
	background: #D9D9D9;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
	background-color: #D9D9D9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
	left: 8px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid #000;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}






// .heading-h1 {
// 	color: #333;
// 	//padding: 0px 16px;		
// 	font-size: 14px;
// 	font-weight: 600;
// 	font-family: 'DM Sans';
// 	margin-bottom: 10px;
// }

.weekdays-subheader {
	background-color: #E9ECEE;
	color: #4D4D4D !important;
	// border-radius: 5px !important;
	border: 0px solid #4c4a4c !important;
	padding: 4px 9px;
	font-size: 16px;
	font-weight: 600;
	background-image: url('../src/assets/img/updated-icons/date_range.svg');
	background-repeat: no-repeat;
	background-position-x: 90%;
	background-position-y: center;
	background-size: 19px;
	margin-right: 20px;
    margin-left: 15px;
}




/* Market Property Modal UI */
.AddEditUpdateCell {
	display: flex;
	justify-content: center;
	align-items: center;

	div {
		display: flex;

		button {
			margin-left: 5px;
		}
	}
}

.market-props-selected input {
	border: 1px solid #ccc;
	padding: 0 5px;
	height: 25px;
	line-height: 26px;
	font-size: 15px;
	border-radius: 3px;
}

.propertyAddBtn,
.action-button.edit,
.action-button.update,
.action-button.cancel {
	border: none;
	padding: 0px 10px;
	font-size: 15px;
	border-radius: 3px;
	// float: right;
	height: 25px;
	line-height: 25px;
	color: #fff;
}


.propertyAddBtn {
	background: #0EB787;
	float: right;
}

.action-button.edit {
	// background: #C5202F;
	// padding: 0px 5px;

}

.action-button.update {
	background: #C5202F;
	color: #fff;
	padding: 0px 5px;
}

.action-button.cancel {
	background: #fff;
	color: #000;
	padding: 0px 5px;
	border: 1px solid #4D4D4D;
}

.ag-cell-inline-editing {
	border: none;
	background: transparent;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
	padding: 0;
	background-color: var(--ag-control-panel-background-color);
	border-color: transparent !important;
}

.ag-center-cols-container {
	width: 100%;
}

input[class^=ag-]:not([type]),
input[class^=ag-][type=text],
input[class^=ag-][type=number],
input[class^=ag-][type=tel],
input[class^=ag-][type=date],
input[class^=ag-][type=datetime-local],
textarea[class^=ag-] {
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	border-radius: 3px !important;
	height: 30px;
	line-height: 30px;
}

.col-form-label {
	font-weight: 600;
}


.ng-select .ng-select-container {
	max-height: 150px;
	overflow: auto !important;
	display: flex;
	// align-items: baseline;
	align-items: center;
	font-size: 14px;
	font-weight: 500;
	color: #4D4D4D;
	border: 1px solid #7C7C7C;
	background-color: #fff !important;
}


.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
	background-color: #FFF6F6 !important;
	color: #C5202F !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: #FFF6F6 !important;
	color: #C5202F !important;
	// font-size: 14px;
	// font-weight: 500;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
	font-size: 14px;
	font-weight: 500;
	color: #4D4D4D;
}

.property-dropdown .ng-dropdown-panel .ng-dropdown-panel-items {
	max-height: none !important;
}

.property-dropdown .ng-select .ng-clear-wrapper .ng-clear {
	display: none !important;
}

.compsetEditBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.compsetEditBtn a {
	border: none;
	padding: 0px 10px;
	font-size: 15px;
	border-radius: 3px;
	float: right;
	height: 25px;
	line-height: 25px;
	color: #fff !important;
	background: #C5202F;
	padding: 0px 5px;
}


/* AgGrid Table Common Style */
.agGridTable {
	// .ag-center-cols-container {
	// 	width: 100% !important;
	// }

	// .agGridHeadStyle {
	// 	padding: 0 10px;
	// 	color: #4C4A4C;
	// 	text-align: center;
	// 	font-size: 14px;
	// 	font-weight: 600;
	// 	font-family: 'DM Sans' !important;
	// 	background-color: #c5c4c5;
	// }

	// .ag-row-odd {
	// 	background-color: #c8c7c8;
	// 	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
	// }

	// .ag-row-even {
	// 	background-color: #f2f2f2;
	// 	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
	// }

	// .ag-row {
	// 	.ag-cell {
	// 		color: #4C4A4C;
	// 		font-family: 'DM Sans' !important;
	// 		font-size: 14px;
	// 		font-weight: 500;
	// 		padding: 0 10px;
	// 		height: 40px;
	// 	}


	// }

	// .ag-center-cols-viewport {
	// 	overflow: hidden;
	// }
}

// .ag-row:hover .edit {
// 	visibility: visible;
// }

// .edit {
// 	visibility: hidden;
// }

// .ag-row-hover:not(.ag-full-width-row)::before,
// .ag-row-hover.ag-full-width-row.ag-row-group::before {
// 	content: "";
// 	background-color: #c9bdbd;
// 	display: block;
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	pointer-events: none;
// }

// .ag-row-hover:not(.ag-full-width-row)::before .agGridTable .ag-row .edit {
// 	display: block;
// }

// .ag-header-cell-label {
// 	justify-content: center !important;
// }

::-webkit-scrollbar {
	width: 8px;
	background: #999;
}

::-webkit-scrollbar-thumb {
	background: #fff;
	border-radius: 3px;
	border: 1px solid #333;
}


/* ===================== 
Custom Checkbox Design 
=======================*/
.custom-checkbox {
	display: inline-block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 16px;
	-webkit-user-select: none;
	user-select: none;
	align-items: center;
	line-height: 1;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px !important;
	border: 1px solid #000;
	background: #D9D9D9;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
	background-color: #D9D9D9;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
	left: 8px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid #000;
	border-width: 0 2px 2px 0;
	transform: rotate(45deg);
}


/*==============================================
 	Common dashboard style 
  ================================================*/


.fp-cout-rate {
	.apexcharts-canvas {
		margin: 0 auto !important;
	}
}

.fpLeasedCount {
	margin-top: -25px;
}

/*  currentSpecialTable  */
.currentSpecialTable {
	margin-top: 5px !important;
	height: 100px;
	width: calc(100% - 30px) !important;
	margin: 0 auto;

	.ag-header {
		color: #000;
		border-bottom: none !important;
	}

	.ag-header-row,
	.ag-row,
	.ag-cell {
		height: 25px !important;
		line-height: 1;
		font-size: 14px !important;
		background-color: transparent;
		color: #fff !important;
		border-bottom: none;
		padding: 5px 10px;
		box-shadow: none;
	}

	.ag-cell-label-container {
		padding: 2px 0 !important;
		color: #000 !important
	}

	.ag-root-wrapper {
		border: none;
	}

	.ag-center-cols-viewport {
		border: none;
		background-color: #797779;
		border-radius: 5px;
	}

	.ag-header-viewport {
		border-bottom: none !important;
		background-color: #fff !important;

		.ag-header-cell {
			padding-left: 10px !important;
		}
	}
}

app-dashboard-subheader {
	display: block;
	width: 100%;
	margin-bottom: 10px;
}


/*graph css changes*/
.apexcharts-text tspan,
.apexcharts-legend-text,
.apexcharts-xaxis-label {
	font-family: 'DM Sans', sans-serif !important;
	font-size: 13px !important;
	font-weight: 400 !important;
	color: #7C7C7C !important;
}

.apexcharts-datalabel-value {
	font-family: 'Barlow Condensed', sans-serif !important;
	font-size: 22px !important;
	font-weight: 500 !important;
}

//setting grid


.icon {
	background-repeat: no-repeat;
	background-position: center center;
	width: 28px;
	height: 28px;
	display: inline-block;
	// color: #222;
	margin-top: 10px;
	font-size: 22px;
	margin-left: -2px;
}

.icon-edit {
	background-image: url('assets/img/edit_icon.svg');
	cursor: pointer;
}

.validate-btn-grid {
	background-color: #044F0B !important;
	color: #fff !important;
	padding: 3px 10px;
	background-image: url('assets/img/edit_icon.svg');
	cursor: pointer;
}

.icon-delete {
	background-image: url('assets/img/icon-delete.svg');
	cursor: pointer;
}

.icon-show {
	background-image: url('assets/img/password-show.svg');
	cursor: pointer;
	height: 20px;
}

.icon-hide {
	background-image: url('assets/img/password-hide.svg');
	cursor: pointer;
	height: 20px;
}

.multi-dropdown-style {
	.dropdown-list {
		top: 0;
		transform: translateY(calc(100% - 340px));


	}

	.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
		animation: none !important;
	}
}

.multiselect-common-style {
	.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
		position: relative;
		right: 0;
		top: 0 !important;
		color: #999;
		margin-top: 4px;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: #999999 transparent;
		border: none !important;
		content: "";
		background-image: url('assets/img/dropdown-arrow.svg');
		background-repeat: no-repeat;
		background-size: 24px;
		background-position: center;
		width: 22px;
		height: 22px;
		display: flex;
		align-items: center;
		justify-content: center;
		filter: invert(40) brightness(0.5);
	}

	.multiselect-dropdown .dropdown-btn .dropdown-multiselect--active .dropdown-multiselect__caret {
		transform: rotate(0deg) !important;
	}

	.multiselect-dropdown .disabled>span {
		background-color: #fff !important;
	}

	.multiselect-item-checkbox:hover {
		background-color: #FFF6F6;
		color: #C5202F;
	}

	.dropdown-btn {
		max-height: 102px;
		overflow-x: hidden;
		overflow-y: auto;
	}

	.selected-item-container a {
		position: relative;
		padding-left: 8px !important;

		&::before {
			content: "";
			position: absolute;
			top: 0px;
			right: -5px;
			background-image: url('assets/img/updated-icons/close_small.svg');
			background-repeat: no-repeat;
			background-size: 22px;
			background-position: center;
			width: 24px;
			height: 24px;
			background-color: #FFF6F6;
		}
	}
}

/*main heading*/
.heading-h1 {
	font-size: 16px;
	font-weight: 600;
	color: #1E1E1E;
	// padding: 6px 10px 0 15px;
	font-family: 'DM Sans';
	line-height: 1;
	position: relative;
	z-index: 9;
	line-height: 28px;
	// text-align: center;
}

.main-h1 {
	font-size: 16px;
	font-weight: 600;
	color: #1E1E1E;
	line-height: 28px;

}

/*coomon css new*/
.page-title::after {
	content: "";
	border: 2px solid #D9D9D9;
	height: 22px;
	margin: 0px 14px;
}

/* Loader Css start*/
.loader-wrapper {
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	height: calc(100% - 60px);
}

.loader-wrapper1 {
	position: fixed;
	width: 98%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.loader-wrapper2 {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.7);
	justify-content: center;
	align-items: center;
	z-index: 1000;
	display: flex;
}

// =====================================loader1===============
// .loader {
// 	width: 50px;
// 	aspect-ratio: 1;
// 	display: grid;
// 	border-radius: 50%;
// 	background:
// 	  linear-gradient(0deg ,rgba(241, 27, 27, 0.5) 30%,#f8313100 0 70%,rgb(0 0 0/100%) 0) 50%/8% 100%,
// 	  linear-gradient(90deg,rgba(241, 27, 27, 0.5) 30%,#f8313100 0 70%,rgb(0 0 0/75% ) 0) 50%/100% 8%;
// 	background-repeat: no-repeat;
// 	animation: l23 1s infinite steps(12);
//   }
//   .loader::before,
//   .loader::after {
// 	 content: "";
// 	 grid-area: 1/1;
// 	 border-radius: 50%;
// 	 background: inherit;
// 	 opacity: 0.915;
// 	 transform: rotate(30deg);
//   }
//   .loader::after {
// 	 opacity: 0.83;
// 	 transform: rotate(60deg);
//   }
//   @keyframes l23 {
// 	100% {transform: rotate(1turn)}
//   }
// =====================================loader2===============
//   .loader {
// 	width: 48px;
// 	height: 48px;
// 	border: 5px dotted #ff5151;
// 	border-radius: 50%;
// 	display: inline-block;
// 	position: relative;
// 	box-sizing: border-box;
// 	animation: rotation 2s linear infinite;
//   }

//   @keyframes rotation {
// 	0% {
// 	  transform: rotate(0deg);
// 	}
// 	100% {
// 	  transform: rotate(360deg);
// 	}
//   } 
// =====================================loader3===============

// .loader {
//   width: 48px;
//   height: 48px;
//   border: 3px dotted #ff5151;
//   border-style: solid solid dotted dotted;
//   border-radius: 50%;
//   display: inline-block;
//   position: relative;
//   box-sizing: border-box;
//   animation: rotation 2s linear infinite;
// }

// .loader::after {
//   content: '';  
//   box-sizing: border-box;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   margin: auto;
//   border: 3px dotted #FF3D00;
//   border-style: solid solid dotted;
//   width: 24px;
//   height: 24px;
//   border-radius: 50%;
//   animation: rotationBack 1s linear infinite;
//   transform-origin: center center;
// }
.loader {
	width: 48px;
	height: 48px;
	border: 3px dotted #ff7e57;
	border-style: solid solid dotted dotted;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 2s linear infinite;
}

.loader::after {
	content: '';
	box-sizing: border-box;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	border: 3px dotted #ff7e57;
	border-style: solid solid dotted;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	animation: rotationBack 1s linear infinite;
	transform-origin: center center;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

@keyframes rotationBack {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(-360deg);
	}
}

/* loader css end */
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	background-color: #FFF6F6 !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
	border-right: 0px solid #FFF6F6;
}